import Web3Modal from 'web3modal';
import Web3 from 'web3';
import { Core } from 'web3modal/dist/core';
import { Contract } from 'web3-eth-contract/types/index';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { WalletLink } from 'walletlink';
import {
  CHAIN_ID,
  CONTRACT,
  ETHERSCAN_KEY,
  ETHERSCAN_URL,
  INFURA_KEY,
  INFURA_URL,
} from '../react-app-env';
import { provider } from 'web3-core';

const infuraUrl = INFURA_URL;
const infuraKey = INFURA_KEY;
const chainId = CHAIN_ID;
const contractAddress = CONTRACT;
const etherscanKey = ETHERSCAN_KEY;
const etherscanUrl = ETHERSCAN_URL;

export default class Web3Connector {
  web3Modal: Core | undefined;
  provider: provider | undefined;
  selectedAccount: string = '';
  web3: Web3 | undefined;
  contract: Contract | undefined;

  initWeb3Modal = (onFail: (message: string) => void) => {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          // network: 'rinkeby',
          infuraId: infuraKey,
        },
      },
      'custom-metamask': {
        display: {
          logo: 'https://nonoronft.com/wp-content/themes/metamax/img/metamask.png',
          name: 'MetaMask Wallet',
          description: 'Connect to your MetaMask Wallet Extension',
        },
        package: true,
        connector: async () => {
          let provider = null;
          if (typeof window.ethereum !== 'undefined') {
            if (window.ethereum.providers) {
              let providers = window.ethereum.providers;
              // @ts-ignore
              provider = providers.find(p => p.isMetaMask); // <-- LOOK HERE
              try {
                await provider.request({
                  method: 'wallet_requestPermissions',
                  params: [
                    {
                      eth_accounts: {},
                    },
                  ],
                });
                // await provider.request({ method: 'eth_requestAccounts' });
              } catch (error) {
                onFail('Connect rejected by user');
              }
            } else {
              provider = window.ethereum;
              await provider.request({
                method: 'wallet_requestPermissions',
                params: [
                  {
                    eth_accounts: {},
                  },
                ],
              });
              // await provider.request({ method: 'eth_requestAccounts' });
            }
          } else {
            onFail('No MetaMask Wallet found');
          }
          return provider;
        },
      },

      'custom-coinbase': {
        display: {
          logo: 'https://nonoronft.com/wp-content/themes/metamax/img/coinbase.png',
          name: 'Coinbase',
          description: 'Connect to Coinbase Wallet',
        },
        options: {
          appName: 'nonoronft',
          networkUrl: infuraUrl,
          chainId: chainId,
        },
        package: WalletLink,
        // @ts-ignore
        connector: async (_, options) => {
          const { appName, networkUrl, chainId } = options;
          const walletLink = new WalletLink({
            appName,
          });
          const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
          await provider.enable();
          return provider;
        },
      },
    };

    this.web3Modal = new Web3Modal({
      // network: 'rinkeby',
      cacheProvider: true, // optional
      providerOptions, // required
      disableInjectedProvider: true, // optional. For MetaMask / Brave / Opera.
    });
  };

  getWeb3 = (): void => {
    if (this.provider) this.web3 = new Web3(this.provider);
  };

  connect = (onFail: (msg: string) => void, onSuccess: () => void) => {
    this.initWeb3Modal(onFail);
    this.connectWallet(onFail)
      .then(payload => {
        return this.getWeb3();
      })
      .then(() => {
        return this.initContract();
      })
      .then(payload => {
        onSuccess();
      })
      .catch(err => {
        if (err) {
          onFail(err.toString());
        }
      });
  };

  connectWallet = async (onFail: (msg: string) => void) => {
    if (this.web3Modal) {
      try {
        this.provider = await this.web3Modal.connect();
      } catch (e) {
        onFail('Could not get a wallet connection');
        return Promise.reject();
      }
      /*const cachedProvider = this.getCachedProvider();
      if (cachedProvider) {
        try {
          this.provider = await this.web3Modal.connect();
        } catch (e) {
          console.error(e);
          onFail('Could not get a wallet connection');
          return Promise.reject();
        }
      } else {
        try {
          this.provider = await this.web3Modal.connect();
        } catch (e) {
          onFail('Could not get a wallet connection');
          return Promise.reject();
        }
      }*/
    }

    if (this.provider) {
      this.walletEventListener();
      await this.findSelectedAccount();
    }
  };

  getCachedProvider = (): string | null => {
    return localStorage.getItem('WEB3_CONNECT_CACHED_PROVIDER');
  };

  walletEventListener = () => {
    // Subscribe to accounts change
    // @ts-ignore
    this.provider.on('accountsChanged', (accounts: string[]) => {
      // @ts-ignore
      // window.location.reload();
    });

    // Subscribe to chainId change
    // @ts-ignore
    this.provider.on('chainChanged', (chainId: string) => {
      // @ts-ignore
      window.location.reload();
    });

    // Subscribe to networkId change
    // @ts-ignore
    this.provider.on('networkChanged', (networkId: number) => {
      // @ts-ignore
      window.location.reload();
    });
  };

  findSelectedAccount = async () => {
    if (!this.provider) return;
    const web3 = new Web3(this.provider);
    const currentChainId = await web3.eth.getChainId();
    if (currentChainId.toString() !== chainId.toString()) {
      return Promise.reject('Switch to correct network and try again');
    }
    const accounts = await web3.eth.getAccounts();
    this.selectedAccount = accounts[0];
  };

  fetchContractAbi = () => {
    //todo change url
    return fetch(
      `${etherscanUrl}/api?module=contract&action=getabi&address=${contractAddress}&apikey=${etherscanKey}`,
    )
      .then(response => response.json())
      .then(data => JSON.parse(data.result));
  };

  initContract = () => {
    return this.fetchContractAbi().then(payload => {
      if (this.web3) {
        // @ts-ignore
        this.web3.eth.Contract.handleRevert = true;
        this.contract = new this.web3.eth.Contract(payload, contractAddress);
        // @ts-ignore
        this.contract.handleRevert = true;
        this.web3.eth.handleRevert = true;
      }
    });
  };

  disconnect = () => {
    // TODO: Which providers have close method?
    /*if (this.provider.close && this.web3ModalInstance) {
      await this.provider.close();

      // If the cached provider is not cleared,
      // WalletConnect will default to the existing session
      // and does not allow to re-scan the QR code with a new wallet.
      // Depending on your use case you may want or want not his behavir.
      await this.web3ModalInstance.clearCachedProvider();
      this.provider = null;
    }*/

    /*if (this.web3 instanceof Web3 && this.web3.eth.currentProvider) {
      // @ts-ignore
      console.log(this.web3.eth.currentProvider);
      // @ts-ignore
      this.web3.eth.currentProvider._handleDisconnect();
    }*/
    this.web3Modal?.clearCachedProvider();
    this.selectedAccount = '';
    this.provider = undefined;
    this.web3 = undefined;
    this.contract = undefined;
  };

  balanceOf = async (address: string): Promise<number> => {
    const balance = await this.contract?.methods.balanceOf(address).call();
    return Number(balance);
  };

  tokensOfOwner = async (address: string) => {
    return this.contract?.methods.tokensOfOwner(address).call();
  };

  getAddress = () => {
    return this.selectedAccount;
  };

  totalSupply = () => {
    return this.contract?.methods.totalSupply().call();
  };

  ownerOf = async (id: number) => {
    return this.contract?.methods.ownerOf(id).call();
  };

  getTokenMetadata = async (id: number) => {
    return Promise.all([
      fetch(`https://nonoronft.herokuapp.com/api/token/${id}`).then(response =>
        response.json(),
      ),
      this.ownerOf(id),
    ])
      .then(payload => {
        return {
          name: payload[0].name,
          img: payload[0].image,
          id: id,
          owner: payload[1],
          hash: payload[0].hash,
        };
      })
      .catch(err => {
        return {
          name: 'Error in reading data',
          img: '',
          id: id,
          owner: 'Error in reading data',
          hash: '',
        };
      });
  };

  signMessage = async (data: string): Promise<string> => {
    if (this.web3) {
      return this.web3.eth.personal.sign(data, this.getAddress(), '');
    }
    return '';
  };

  ecRecover = async (data: string, signature: string) => {
    if (this.web3) {
      return this.web3.eth.personal.ecRecover(data, signature);
    }
  };
}
