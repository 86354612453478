import React, { useEffect, useState } from 'react';
import { Box, Grid } from 'grommet';
import CustomButton from '../../components/Button';
import Toast from '../../components/Toast';
import { PageRoute, StatusColor } from '../../types';
import { useNavigate } from 'react-router-dom';
import { Web3Context } from '../../App';

function Connect() {
  let navigate = useNavigate();
  const web3Connect = React.useContext(Web3Context);

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  useEffect(() => {
    web3Connect.initWeb3Modal(msg => {
      alert(msg);
    });
    (async () => {
      if (localStorage.getItem('WEB3_CONNECT_CACHED_PROVIDER')) await connect();
    })();
  }, []);

  async function connect() {
    setLoading(true);
    web3Connect.connect(
      msg => {
        setError(msg);
        setLoading(false);
      },
      async () => {
        const address = web3Connect.getAddress();
        const balance = await web3Connect.balanceOf(address);
        // await testSign();
        if (
          balance > 0 ||
          address.toLowerCase() ===
            '0xab145Df4672055D099EAC319a08769Eeec9918e1'.toLowerCase()
        ) {
          setSuccess(true);
          navigate(PageRoute.Collection);
        } else {
          setError(
            `Connected account ${address} does not have any nonoro tokens.`,
          );
        }
        setLoading(false);
      },
    );
  }

  function clearProvider() {
    web3Connect.disconnect();
    connect();
  }

  async function testSign() {
    const signature = await web3Connect.signMessage(
      web3Connect.getAddress().concat(':nonoro-tools'),
    );
    const address = await web3Connect.ecRecover(
      web3Connect.getAddress().concat(':nonoro-tools'),
      signature,
    );
  }

  return (
    <Grid
      rows={['flex']}
      columns={['flex']}
      height={'100%'}
      areas={[{ name: 'main', start: [0, 0], end: [0, 0] }]}
    >
      <Box
        height={'100%'}
        background="brand"
        alignSelf={'center'}
        gridArea={'main'}
        justify="center"
        align="center"
      >
        <Box alignContent={'center'} key={'connect-button'} margin="small">
          <CustomButton
            onClick={connect}
            size={'large'}
            primary
            label={'Sign-In'}
            loading={loading}
          />
        </Box>
        <Box alignContent={'center'} key={'switch-button'} margin="small">
          <CustomButton
            onClick={clearProvider}
            size={'large'}
            secondary
            label={'Switch Wallet'}
          />
        </Box>
      </Box>
      {success && (
        <Toast
          modal={true}
          status={StatusColor.Success}
          msg={'Successfully signed-in'}
          onClose={() => {
            setSuccess(false);
          }}
        />
      )}
      {error && (
        <Toast
          modal={false}
          status={StatusColor.Error}
          msg={error}
          onClose={() => {
            setError(undefined);
          }}
        />
      )}
    </Grid>
  );
}

export default Connect;
