export const INFURA_KEY =
  process.env.REACT_APP_INFURA_KEY || '2d5535188bde494f97fa6e235d412a94';
export const INFURA_URL =
  process.env.REACT_APP_INFURA_URL ||
  'https://mainnet.infura.io/v3/2d5535188bde494f97fa6e235d412a94';
export const CHAIN_ID = process.env.REACT_APP_CHAIN_ID || '1';
export const CONTRACT =
  process.env.REACT_APP_CONTRACT ||
  '0xac18604eec3df3c4bcc75570e2450b1bd2a6f5de';
export const ETHERSCAN_KEY =
  process.env.REACT_APP_ETHERSCAN_KEY || '48KNX1MVPM72747KJ9Q813V878A22B67DD';
export const ETHERSCAN_URL =
  process.env.REACT_APP_ETHERSCAN_URL || 'https://api.etherscan.io';
