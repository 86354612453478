import { Button, Spinner } from 'grommet';
import React from 'react';
import { ButtonExtendedProps } from 'grommet/components/Button';

interface ExtendedButtonProps extends ButtonExtendedProps {
  loading?: boolean;
}

function CustomButton(props: ExtendedButtonProps) {
  return (
    <Button
      {...props}
      label={props.loading ? <Spinner color={'white'} /> : props.label}
    />
  );
}

export default CustomButton;
